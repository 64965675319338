<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Email Marketing - Configuración</h4>
            <div class="small text-muted">Configurar la conexión para conectarnos al servicio</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      
            <b-tabs v-model="tabIndex" card>
              
          
              <b-tab title="Principal" :title-link-class="linkClass(0)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Envialo Simple
                      <small>
                        <b-badge variant="success" v-if="crud.form.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12" xs="12">
                      <b-row>                                                
                        <b-col md="10">
                          <label v-if="crud.form.active">Clave Privada ({{crud.form.name}})</label>
                          <label v-else>Clave Privada</label>

                          <b-form-input type="text"
                                        size="sm"
                                        class="mb-2"
                                        v-model="crud.form.key"    
                                        :disabled="crud.form.active==true"                           
                                        placeholder="">
                          </b-form-input>                          
                        </b-col>
                        <b-col md="2">
                          <b-button variant="outline-dark" 
                                    size="sm" 
                                    @click="changeTracking()"
                                    class="email-marketing-configuration-tracking-button"
                                    v-if="crud.form.active"
                                    v-b-tooltip.hover 
                                    title="Quitar la cuenta monitoreada">
                              <b-icon icon="x-circle"></b-icon>
                          </b-button> 
                        </b-col>                        
                      </b-row>
                      
                      <b-row>
                        <b-col md="6">                          
                          <b-button variant="dark" 
                                    size="sm" 
                                    v-if="!crud.form.active"
                                    @click="saveEmailMarketing()">
                            Guardar y Conectar
                          </b-button>                        
                        </b-col>                                               
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12" xs="12">
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Para conectar con el servicio, se debe ingresar a  
                          <a href="https://envialosimple.com/" target="_blank">Envialo Simple</a>                          
                          <br><br>
                          1). En la configuración de API, se le asigna una <strong>Clave Privada</strong>.<br>
                          2). Copiarla y pegarla en nuestra configuración.<br>
                          3). Por último, click en <strong>Guardar y Conectar</strong>                          
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>              

            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.EMAIL_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: { 
          form: {
            id: 1,         
            name: '',  
            key: '',
            active: false,
          },             
        },   
        status: {
          connect: false
        },    
        tabIndex: 0,            
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.showEmailMarketing()        
    },
    computed: {
      okConnect() {
        if(this.crud.form.key) {
          return true
        }
      }
    },    
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },

      showEmailMarketing() {        
        let loader = this.$loading.show(); 
        var result = serviceAPI.mostrarEmailMarketing(this.crud.form.id)

        result.then((response) => {
          loader.hide()
          var data = response.data
          
          this.crud.form.name = data.name          
          this.crud.form.key = data.key          
          this.crud.form.active = data.active
        }) 
        .catch(error => {         
          loader.hide() 
          this.$awn.alert(Error.showError(error));
        })
      },
      saveEmailMarketing() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de Email Marketing?', {
          title: 'Guardar Email Marketing',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();
            var result = serviceAPI.editarEmailMarketing(this.crud.form);

            result.then((response) => {          
              loader.hide()          
              this.showEmailMarketing()

              if(typeof response.data === 'string') {
                this.$awn.warning(response.data);
              } else {
                this.$awn.success("Se guardaron los datos de Email Marketing y se conecto a Envialo Simple con éxito");
              }
              
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },    
      changeTracking() {
        this.$bvModal.msgBoxConfirm('Quitar la cuenta ' + this.crud.form.name + '?', {
          title: 'Email Marketing',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'QUITAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.cambiarCuentaEmailMarketing(this.crud.form)

            result.then((response) => {                        
              loader.hide()
              this.showEmailMarketing()
              this.$awn.success('Cuenta liberada')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      }        
    } 
  }
</script>
<style>
  .email-marketing-configuration-tracking-button {
    margin-top: 30px;
  }
</style>